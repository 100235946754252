import styled, { css } from 'styled-components';
import { Layout as AntdLayout, Skeleton } from 'antd';

export const Wrapper = styled(AntdLayout)`
  height: 100vh;
  width: 100vw;
  background: transparent;
  overflow-y: hidden;
  background: url('/images/boarding.svg') no-repeat center center fixed;
  background-size: cover;

  @media print {
    height: auto;
    overflow: visible;
  }
`;

export const DarkModeOverlay = styled.div<{
  $darkMode?: boolean;
  $404?: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.black};
  opacity: ${({ $darkMode }) => ($darkMode ? 0.5 : 0)};
  transition: opacity 0.25s ease-in-out;
  pointer-events: none;
  z-index: ${({ $404 }) => ($404 ? 1000 : 'unset')};
`;

/* 
Without 'width: <N>px' border of layout becomes hidden when size changed 
You could test it if add style 'border: 3px solid red;'
*/
export const Layout = styled.div<{ $overflowY?: 'auto' }>`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 650px;

  ${({ $overflowY }) =>
    $overflowY &&
    css`
      overflow-y: ${$overflowY};
    `}
`;

export const PagePlaceholder = styled.div
  // https://github.com/styled-components/styled-components/issues/3125#issuecomment-811334966
  .withConfig({
    componentId: 'PagePlaceholder',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any)`
  display: flex;
  flex: 0 auto;
  flex-direction: column;
  padding: 48px 72px;
  border-radius: 18px;
  background: ${({ theme }) => theme.colors.white};
  margin: 36px;
  gap: 72px;
`;

const SKELETON_HEIGHT = {
  small: '32px',
  medium: '40px',
  large: '48px',
};

export const SkeletonBox = styled(Skeleton).attrs(
  ({ paragraph = false, title = true }) => ({
    title,
    paragraph,
    active: true,
  })
)<{ width?: string; size: keyof typeof SKELETON_HEIGHT; rounded?: boolean }>`
  width: ${({ width = '100%' }) => width};

  .ant-skeleton-title {
    border-radius: ${({ rounded }) => (rounded ? '12px' : '8px')};
    height: ${({ size }) => SKELETON_HEIGHT[size]};
  }
`;
