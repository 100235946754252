import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useFirebase } from './context';
import { useRouter } from 'next/router';
import { Claims } from './state';
import { ClientUserPermissionEnum } from '@vette/data-access';

type Props = {
  fallback?: React.ReactNode;
  required?: { [key in keyof Claims]?: boolean };
  children: (
    claims: NonNullable<ReturnType<typeof useFirebase>['claims']>
  ) => React.ReactNode;
};

export const FirebaseClaims: React.FC<Props> = ({
  children,
  required,
  fallback,
}) => {
  const { user, claims, logout, refetchToken } = useFirebase();
  const router = useRouter();

  const checkRequiredClaimsMissing = <T extends typeof claims>(
    currentClaims: T
  ) =>
    user &&
    (!currentClaims ||
      isEmpty(currentClaims) ||
      (!currentClaims.id && required?.id) ||
      (!currentClaims.clientId && required?.clientId) ||
      (!currentClaims.clientUserPermission && required?.clientUserPermission));

  useEffect(() => {
    if (
      checkRequiredClaimsMissing(claims) &&
      !['/login', '/signup', '/invitation'].includes(router.asPath)
    ) {
      refetchToken().then(idToken => {
        const isAdmin = idToken?.claims?.role === 'ADMIN';

        // This is required to allow admins to go through Clients > Manage
        // We will get "clientId" from the router rather than claims
        const normalizedClaims = isAdmin
          ? {
              ...idToken.claims,
              clientId: (router.query?.clientId ||
                idToken?.claims?.clientId) as string | undefined,
              clientUserPermission: ClientUserPermissionEnum.INTERVIEW,
            }
          : idToken?.claims;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (checkRequiredClaimsMissing(normalizedClaims as any)) {
          console.log('Required claims missing, logging out...', {
            claims: normalizedClaims,
            required,
          });
          logout().then(() => router.replace('/login#'));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logout, router]);

  if (!claims || checkRequiredClaimsMissing(claims)) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{fallback || <></>}</>;
  }

  return <>{children(claims)}</>;
};
