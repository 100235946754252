import React, { useCallback, useEffect, useRef } from 'react';
import { Tooltip } from 'antd';
import {
  useUpdatePresenceMutation,
  useUserPresenceQuery,
  useVetterUpdatedSubscription,
  UserPresenceEnum,
} from '@vette/data-access';
import { message } from '@vette/frontend-utils';
import { Switch, theme } from '../../common';
import NoSleep from 'nosleep.js';

import * as S from './styles';

type Props = {
  userId: string;
};

export const OnlineOfflineSwitch: React.FC<Props> = ({ userId }) => {
  const noSleepRef = useRef<NoSleep>();
  const [updateUserPresence] = useUpdatePresenceMutation();
  const { data, loading } = useUserPresenceQuery({
    variables: { userId },
  });

  useVetterUpdatedSubscription({
    variables: {
      userId,
    },
  });

  const checked =
    data?.user?.presence === UserPresenceEnum.ONLINE ||
    data?.user?.presence === UserPresenceEnum.BUSY;

  const updateVetter = useCallback(async () => {
    const newStatus = checked
      ? data?.user?.presence === UserPresenceEnum.BUSY
        ? UserPresenceEnum.ONLINE
        : UserPresenceEnum.OFFLINE
      : UserPresenceEnum.ONLINE;

    if (checked) {
      await noSleepRef.current?.enable();
    } else {
      noSleepRef.current?.disable();
    }

    const { data: mutationData } = await updateUserPresence({
      variables: {
        userId,
        presence: newStatus,
      },

      optimisticResponse: {
        __typename: 'Mutation',
        updateUserPresence: {
          __typename: 'User',
          id: userId,
          presence: newStatus,
        },
      },
    });

    if (mutationData?.updateUserPresence?.__typename === 'ValidationError') {
      message.error(mutationData.updateUserPresence.messages?.[0]);
    }
  }, [updateUserPresence, userId, checked, data?.user?.presence]);

  const accountSetupCompleted =
    data?.user?.isProfileDataProvided && data?.user?.isProfilePhotoProvided;
  const isApproved = data?.user?.approvalStatus === 'ACTIVE';
  const canVette = accountSetupCompleted && isApproved;

  let tooltipMessage =
    'Before you start Vetting you must first provide your profile photo.';
  if (accountSetupCompleted && !isApproved) {
    tooltipMessage =
      'Your account must be approved before you are able to start Vetting';
  }

  if (canVette) {
    tooltipMessage =
      data?.user?.presence === UserPresenceEnum.ONLINE
        ? 'While you are online, you can expect to receive interview requests'
        : 'While you are offline, you will not get incoming Vettes.';
  }

  useEffect(() => {
    const noSleepLock = new NoSleep();
    noSleepRef.current = noSleepLock;
  });

  if (!data?.user?.photoHighUrl) {
    return (
      <Switch
        disabled={false}
        checkedChildren={UserPresenceEnum.ONLINE || UserPresenceEnum.BUSY}
        unCheckedChildren={UserPresenceEnum.OFFLINE}
        checked={checked}
        onClick={() =>
          message.error(
            <>
              Oops! Please upload a profile photo in{' '}
              <S.Link href="/settings">Settings</S.Link> to go online.
            </>
          )
        }
        loading={loading}
      />
    );
  }

  return (
    <Tooltip title={tooltipMessage}>
      <Switch
        aria-label={checked ? 'Go Offline' : 'Go Online'}
        disabled={!canVette}
        checkedChildren={
          data?.user?.presence === UserPresenceEnum.ONLINE
            ? UserPresenceEnum.ONLINE
            : UserPresenceEnum.BUSY
        }
        unCheckedChildren={UserPresenceEnum.OFFLINE}
        checked={checked}
        onClick={updateVetter}
        loading={loading}
        color={
          data?.user?.presence === UserPresenceEnum.BUSY
            ? theme.colors.yellow100
            : undefined
        }
      />
    </Tooltip>
  );
};
