import styled from 'styled-components';

export const Link = styled.a`
  color: #fff;
  text-decoration: underline;

  &:hover {
    color: #fff;
  }
`;
