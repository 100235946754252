import { Row } from 'antd';
import React from 'react';
import { Sider } from './Sider';
import { useUserPresenceQuery, UserPresenceEnum } from '@vette/data-access';
import {
  firebase,
  hooks,
  isAdminAsClient,
  useCustomRouter,
} from '@vette/frontend-utils';
import { useTheme } from 'styled-components';

import * as S from './styles';

type Props = React.PropsWithChildren<{
  loading?: boolean;
  hasStatusToggle?: boolean;
  fullLogo?: boolean;
  overflowY?: 'auto';
}>;

export const DashboardLayout: React.FC<Props> = ({
  children,
  loading,
  hasStatusToggle,
  fullLogo,
  overflowY,
}) => {
  const { claims } = firebase.useFirebase();
  const { router } = useCustomRouter();
  const { isClientManager } = hooks.useRole();

  const isEligibleForInterview =
    !isClientManager && !isAdminAsClient(router.asPath);

  const { data } = useUserPresenceQuery({
    variables: { userId: claims?.id ?? '' },
    skip: !claims?.id || !isEligibleForInterview || !hasStatusToggle,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const theme: any = useTheme();

  const darkMode =
    data?.user?.presence === UserPresenceEnum.OFFLINE &&
    theme.config.allowDarkMode;

  return (
    <S.Wrapper hasSider>
      <S.DarkModeOverlay $darkMode={darkMode} $404={fullLogo} />
      <Sider
        fullLogo={fullLogo}
        hasStatusToggle={hasStatusToggle && isEligibleForInterview}
      />
      <S.Layout $overflowY={overflowY}>
        {loading ? (
          <S.PagePlaceholder>
            <Row justify="space-between" align="middle">
              <S.SkeletonBox width="420px" size="medium" />
              <S.SkeletonBox width="130px" size="large" rounded />
            </Row>
            <S.SkeletonBox size="small" paragraph title />
          </S.PagePlaceholder>
        ) : (
          children
        )}
      </S.Layout>
    </S.Wrapper>
  );
};
